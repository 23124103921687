import React, { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AppSettings, commonImgPath } from '@/shared/app-common';

import styles from './index.module.less';
import { useRouter } from 'next/router';
import classNames from 'classnames';

const Card = ({
  title,
  desc,
  imgPath,
  link,
  isRight,
}: {
  title: string;
  desc: string;
  imgPath: string;
  link?: string;
  isRight?: boolean;
}) => {
  const router = useRouter();
  return (
    <div
      onClick={() => link && router.push(link)}
      className={styles.card}
      style={{ backgroundImage: `url(${imgPath})` }}
    >
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={styles.desc}
        style={{ width: isRight ? '60%' : '55%' }}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </div>
  );
};

const MemoizedCard = memo(Card);

const DetailShowContent = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("Quickly Stay Updated on the Latest Industry Releases"),
      desc: t(
        `Stay competitive with timely updates on new product developments, empowering informed decisions and keeping you at the forefront of your industry.`,
      ),
      imgPath: commonImgPath + '/show-1.webp',
      // link: `/blog/how-can-i-see-my-girlfriends-activity-on-instagram`,
    },
    {
      title: t("Get Real-Time Notifications to Stay on Top of Inventory Status"),
      desc: t(`Never miss restock alerts again. Monitor inventory in real time and get instant notifications when items are back in stock, keeping you informed on critical inventory changes.`),
      imgPath: commonImgPath + '/show-2.webp',
      // link: `/blog/moms-teach-teens-an-important-lesson-ensuring-online-safety`,
    },
    {
      title: t(`Smart Monitoring to Help You Track Price Changes`),
      desc: t(
        `Monitor price changes in real time and receive instant alerts, enabling you to quickly adapt your strategy and seize discount opportunities for a competitive edge.`
      ),
      imgPath: commonImgPath + '/show-3.webp',
      // link: `/blog/the-best-instagram-tool-for-finding-your-competitors-potential-customers-in-2024`,
    },
    {
      title: t(`Gather Industry Insights to Support Your Decisions`),
      desc: t(`Continuously monitor similar product websites for the latest updates, ensuring you stay informed about vital market changes that impact your business.`),
      imgPath: commonImgPath + '/show-4.webp',
      isRight: true,
      // link: `/blog/how-to-buy-my-girlfriend-the-perfect-gift`,
    },
    {
      title: t(`Maintain a Competitive Edge Amid Policy Changes`),
      desc: t(
        `Monitor government websites for real-time alerts on policies and regulations, ensuring you stay compliant and informed for better decision-making.`
      ),
      imgPath: commonImgPath + '/show-5.webp',
      isRight: true,
      // link: `/blog/how-to-track-top-uk-celebrities-instagram-activity-using-dolphin-radar`,
    },
    {
      title: t(`Smart Analysis to Help You Capture Key Insights`),
      desc: t(
        `Stay updated on trends, tracking real-time search results to support informed decisions and effective strategies.`
      ),
      imgPath: commonImgPath + '/show-6.webp',
      isRight: true,
      // link: `/blog/how-to-track-top-uk-celebrities-instagram-activity-using-dolphin-radar`,
    },
  ];

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{t(`InstantKnow helps you get what you want`)}</h2>
        <div className={styles.text}>
          <p>{t(`Do you want to stay updated on website changes in real-time? InstantKnow makes it easy for you to get the latest website updates!`)}</p>
        </div>

        <div className={classNames([styles.bottomContainerPC])}>
          <div className={styles.leftSubContainer}>
            {items.slice(0, 3).map((item, index) => (
              <MemoizedCard key={index} {...item} />
            ))}
          </div>

          <div className={styles.leftSubContainer}>
            {items.slice(3).map((item, index) => (
              <MemoizedCard key={index} {...item} />
            ))}
          </div>
        </div>

        <div className={classNames([styles.bottomContainerMobile])}>
          {items.map((item, index) => (
            <MemoizedCard key={index} {...item} />
          ))}
        </div>
      </div>
    );
  }, [items]);

  return <>{getContent}</>;
};

export default DetailShowContent;
